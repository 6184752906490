<template>
  <v-dialog
    v-model="dialog"
    max-width="1156"
    content-class="content"
    class="payment-details"
    attach
    persistent
  >
    <v-card class="sw-py-3 sw-px-6">
      <v-btn
        flat
        icon
        class="ma-0 sw-accent-bg sw-on-accent close"
        @click="dialog = false"
      >
        <v-icon small>close</v-icon>
      </v-btn>
      <v-layout row wrap class="sw-mb-3">
        <v-flex xs6 class="sw-pr-3">
          <v-layout row wrap>
            <v-flex xs12 class="sw-mb-3 sw-h5">Add payment information</v-flex>
            <v-flex xs12 :class="{ 'is-loading': isLoading }">
              <v-layout row wrap>
                <v-flex xs12 class="sw-mb-1">
                  <div class="mb-1 sw-caption sw-font-weight-light">
                    Payment method
                  </div>
                  <v-select
                    hide-details
                    disabled
                    :placeholder="'Credit/debit card'"
                    solo
                  ></v-select>
                  <v-layout row class="align-center pt-1">
                    <span class="pr-1"
                      ><img :src="require(`@/assets/images/visa.png`)"
                    /></span>
                    <span class="pr-1"
                      ><img :src="require(`@/assets/images/mastercard.png`)"
                    /></span>
                    <span class="pr-1"
                      ><img :src="require(`@/assets/images/stripe.png`)"
                    /></span>
                  </v-layout>
                </v-flex>
                <template v-if="isReady && !paymentMethod.card">
                  <v-flex xs12>
                    <div class="mb-1 sw-caption sw-font-weight-light">
                      Credit/debit card number*
                    </div>
                    <card-number
                      ref="cardNumber"
                      :stripe="stripeKey"
                      :options="{ placeholder: '', ...cardElementStyle }"
                      class="stripe-element card-number mb-4"
                      @change="number = $event.complete"
                    />
                  </v-flex>
                  <v-flex xs6 class="sw-pr-1">
                    <div class="mb-1 sw-caption sw-font-weight-light">
                      Card expiration*
                    </div>
                    <card-expiry
                      ref="cardExpiry"
                      :stripe="stripeKey"
                      :options="{ placeholder: 'MM / YY', ...cardElementStyle }"
                      class="stripe-element card-expiry mb-4"
                      @change="expiry = $event.complete"
                    />
                  </v-flex>
                  <v-flex xs6 class="sw-pl-1">
                    <div class="mb-1 sw-caption sw-font-weight-light">CVC*</div>
                    <card-cvc
                      ref="cardCvc"
                      :stripe="stripeKey"
                      :options="{ placeholder: '', ...cardElementStyle }"
                      class="stripe-element card-cvc mb-4"
                      @change="cvc = $event.complete"
                    />
                  </v-flex>
                </template>
                <template v-if="isReady && paymentMethod.card">
                  <v-flex xs12 class="mb-4">
                    <div class="mb-1 sw-caption sw-font-weight-light">
                      Selected card
                    </div>
                    <v-layout row wrap class="align-center">
                      <v-flex xs6 class="grey--text">
                        <span class="text-capitalize">{{
                          paymentMethod.card.brand
                        }}</span
                        >, ending **** {{ paymentMethod.card.last4 }}
                      </v-flex>
                      <v-flex xs6 class="text-right">
                        <span
                          class="sw-caption sw-accent cursor-pointer"
                          @click="deletePaymentMethod"
                        >
                          Delete
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </template>
                <v-flex xs6 class="sw-pr-1">
                  <div class="mb-1 sw-caption sw-font-weight-light">
                    First name*
                  </div>
                  <v-text-field
                    v-model="paymentAddress.first_name"
                    solo
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 class="sw-pl-1">
                  <div class="mb-1 sw-caption sw-font-weight-light">
                    Last name*
                  </div>
                  <v-text-field
                    v-model="paymentAddress.last_name"
                    solo
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6 class="sw-pl-3">
          <v-layout row wrap>
            <v-flex xs12 class="sw-mb-3 sw-h5">Add company details</v-flex>
            <v-flex xs12 :class="{ 'is-loading': isLoading }">
              <v-layout row wrap>
                <v-flex xs12>
                  <div class="mb-1 sw-caption sw-font-weight-light">
                    Company
                  </div>
                  <v-text-field
                    v-model="paymentAddress.organization"
                    solo
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <div class="mb-1 sw-caption sw-font-weight-light">
                    Street address
                  </div>
                  <v-text-field
                    v-model="paymentAddress.street"
                    solo
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 class="sw-pr-1">
                  <div class="mb-1 sw-caption sw-font-weight-light">
                    Country/region
                  </div>
                  <v-text-field
                    v-model="paymentAddress.state"
                    solo
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 class="sw-pl-1">
                  <div class="mb-1 sw-caption sw-font-weight-light">
                    Postal code
                  </div>
                  <v-text-field
                    v-model="paymentAddress.postal_code"
                    solo
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 class="sw-pr-1">
                  <div class="mb-1 sw-caption sw-font-weight-light">
                    CVR / VAT ID
                  </div>
                  <v-text-field
                    v-model="paymentAddress.vat_number"
                    solo
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <div
        v-if="cardSetupErrorMessage"
        class="sw-mb-1 sw-caption text-center red--text"
      >
        {{ cardSetupErrorMessage }}
      </div>
      <div class="text-center">
        <v-btn
          round
          large
          class="sw-accent-bg sw-on-accent text-none"
          :loading="isLoading"
          :disabled="!isCardDetailsValid"
          @click="savePaymentDetails"
          >{{ confirmationBtnText }}</v-btn
        >
      </div>
    </v-card>
    <ConfirmationDialog ref="confirmationDialog" />
  </v-dialog>
</template>

<script>
import {
  CardNumber,
  CardExpiry,
  CardCvc,
  handleCardSetup,
} from "vue-stripe-elements-plus";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isReady: false,
    isLoading: false,
    cardSetupErrorMessage: "",
    number: false,
    expiry: false,
    cvc: false,
    paymentMethod: {},
    paymentAddress: {},
  }),
  computed: {
    stripeKey() {
      return process.env.VUE_APP_STRIPE_KEY;
    },
    defaultCurrency() {
      return process.env.VUE_APP_DEFAULT_CURRENCY;
    },
    cardElementStyle() {
      return {
        style: {
          base: {
            fontFamily: '"Poppins", Arial, Helvetica, sans-serif',
            lineHeight: "36px",
            fontSize: "16px",
            fontWeight: "300",
            "::placeholder": {
              fontSize: "12px",
              color: "#b6b6b6",
            },
          },
        },
      };
    },
    isCardDetailsValid() {
      if (!this.paymentAddress.first_name || !this.paymentAddress.last_name) {
        return false;
      }

      return (
        (this.number && this.expiry && this.cvc) ||
        (this.paymentMethod && this.paymentMethod.id)
      );
    },
    confirmationBtnText() {
      if (!this.paymentMethod.id) {
        return `Authenticate card 0.00 ${this.defaultCurrency}`;
      }
      return "Save details";
    },
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  components: {
    CardNumber,
    CardExpiry,
    CardCvc,
  },
  watch: {
    dialog(val) {
      if (val) this.fetchAll();
    },
  },
  methods: {
    async fetchAll() {
      try {
        this.isLoading = true;

        await this.listPaymentMethods();
        await this.listAddresses();

        this.isReady = true;
        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.$store.dispatch("addErrorNotification", {
            message: this.$t("common.server_error"),
          });
          this.isReady = true;
          this.isLoading = false;
        }
      }
    },
    async listPaymentMethods() {
      const response = await this.$api.authStripe.list();

      if (!response.data.data || !response.data.data[0]) {
        return;
      }

      this.paymentMethod = response.data.data[0];
    },
    async listAddresses() {
      const response = await this.$api.authAddresses.list();

      if (!response.data.data || !response.data.data[0]) {
        return;
      }

      this.paymentAddress = response.data.data[0];
    },
    async createPaymentMethod() {
      const intentResponse = await this.$api.authStripe.intent();

      const clientSecret = intentResponse.data.data.client_secret;
      // const clientData = {
      //   payment_method_data: {
      //     metadata: {
      //       first_name: this.paymentAddress.first_name,
      //       last_name: this.paymentAddress.last_name,
      //       organization: this.paymentAddress.organization,
      //       street: this.paymentAddress.street,
      //       state: this.paymentAddress.state,
      //       postal_code: this.paymentAddress.postal_code,
      //       vat_number: this.paymentAddress.vat_number,
      //     },
      //   },
      // };

      // const cardSetupResponse = await handleCardSetup(clientSecret, clientData);
      const cardSetupResponse = await handleCardSetup(clientSecret);

      if (cardSetupResponse.error) {
        this.isLoading = false;
        this.cardSetupErrorMessage = cardSetupResponse.error.message;

        throw cardSetupResponse.error.message;
      } else {
        this.cardSetupErrorMessage = "";
      }

      const paymentMethodId = cardSetupResponse.setupIntent.payment_method;

      const specs = {
        payment_method_id: paymentMethodId,
      };

      await this.$api.authStripe.create(specs);

      this.$emit("method-created");

      await this.listPaymentMethods();
    },
    transformAddressForSave(address) {
      if (!address) return {};

      return {
        first_name: address.first_name,
        last_name: address.last_name,
        organization: address.organization,
        street: address.street,
        state: address.state,
        postal_code: address.postal_code,
        vat_number: address.vat_number,
      };
    },
    async createAddress() {
      const specs = {
        label: "Default address",
        ...this.transformAddressForSave(this.paymentAddress),
      };

      await this.$api.authAddresses.create(specs);

      this.$emit("address-created");
    },
    async updateAddress() {
      const specs = [
        this.paymentAddress.id,
        this.transformAddressForSave(this.paymentAddress),
      ];

      await this.$api.authAddresses.update(...specs);

      this.$emit("address-updated");
    },
    async savePaymentDetails() {
      try {
        this.isLoading = true;

        if (!this.paymentMethod.id) {
          await this.createPaymentMethod();
        }

        if (!this.paymentAddress.id) {
          await this.createAddress();
        } else {
          await this.updateAddress();
        }

        this.isLoading = false;

        this.$emit("success");

        this.dialog = false;
      } catch (error) {
        if (error) {
          this.$store.dispatch("addErrorNotification", {
            message: this.$t("common.server_error"),
          });
          this.isLoading = false;
        }
      }
    },
    async deletePaymentMethod() {
      const isConfirmed = await this.$refs.confirmationDialog.open(
        "Are you sure you want to delete this card?",
        this.$t("common.no"),
        this.$t("common.yes"),
      );

      if (!isConfirmed) return;

      try {
        this.isLoading = true;

        await this.$api.authStripe.delete();

        this.isLoading = false;

        this.$emit("method-deleted");

        this.number = false;
        this.expiry = false;
        this.cvc = false;
        this.paymentMethod = {};
      } catch (error) {
        if (error) {
          this.$store.dispatch("addErrorNotification", {
            message: this.$t("common.server_error"),
          });
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.payment-details {
  .content {
    border-radius: 19px !important;
  }

  .v-dialog {
    overflow: visible !important;

    .close {
      width: 32px;
      height: 32px;
      position: absolute;
      top: -14px;
      right: -14px;
    }
  }

  .v-card {
    border-radius: 19px !important;

    .v-text-field.v-text-field--solo .v-input__control {
      min-height: 36px !important;
    }

    .v-input__slot {
      border-radius: 5px !important;
      background-color: #f2f2f2 !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }

    .card-number,
    .card-expiry,
    .card-cvc {
      padding: 0 12px;
      border-radius: 5px !important;
      background-color: #f2f2f2;
    }
  }
}
</style>
